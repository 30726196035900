import React from "react"
import { graphql, Link } from "gatsby"
import LayoutDark from "../pages/shared/layout/layout-dark"
import SignUpFormComponent from "../pages/shared/components/sign-up-form.component"
import { Helmet } from "react-helmet"

const routes = require("../types/routes")
export const query = graphql`
  query AuthorBySlug($slug: String!) {
    ghostAuthor(slug: { eq: $slug }) {
      name
      profile_image
      postCount
      slug
    }
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { authors: { elemMatch: { slug: { eq: "beth" } } } }
    ) {
      edges {
        node {
          id
          html
          title
          slug
          feature_image
          excerpt
          reading_time
          created_at(formatString: "DD MMMM YYYY")
          primary_author {
            name
            profile_image
            slug
          }
          primary_tag {
            name
          }
        }
      }
    }
  }
`

class BlogAuthor extends React.Component {
  constructor(props) {
    super(props)
    this.blog = React.createRef()
    this.state = {
      routes: routes,
    }
  }

  componentDidMount() {
    document.querySelector(".navbar").classList.add("black")
    this.linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`
    this.twitterShareUrl = `https://twitter.com/share?url=${window.location.href}`
    this.facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`
    this.url = process.env.BASE_URL
  }
  componentWillMount() {
    const query = [...this.props.data.allGhostPost.edges]
    this.setState({ defaultQuery: query })
  }

  onSharePage(url) {
    const width = 650
    const height = 450
    window.open(
      url,
      "Share Contingent",
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" +
        width +
        ",height=" +
        height +
        ",top=" +
        (window.screen.height / 2 - height / 2) +
        ",left=" +
        (window.screen.width / 2 - width / 2)
    )
  }

  render() {
    this.author = this.props.data.ghostAuthor
    return (
      <LayoutDark>
        <Helmet>
          <title>{this.author.name} - Contingent Blog</title>
          <meta
            name="title"
            content={this.author.title + "- Contingent Blog"}
          />
          <meta name="description" content={this.author.excerpt} />
          <link
            rel="canonical"
            href={`${process.env.BASE_URL}/blog/${this.author.slug}`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/blog/${this.author.slug}`}
          />
          <meta
            property="og:title"
            content={this.author.title + "- Contingent Blog"}
          />
          <meta property="og:description" content={this.author.excerpt} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/blog/${this.author.slug}`}
          />
          <meta property="og:image" content={`${this.author.profile_image}`} />
        </Helmet>
        <div className={"section-container blog"}>
          <div className={"section-info basic"}>
            <div className={"container"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content blog-sub-section"}>
                    <div className={"author"}>
                      <div className={"group"}>
                        <img
                          className={"avatar"}
                          src={this.author.profile_image}
                        />
                        <p className={"name"}>{this.author.name}</p>
                      </div>
                    </div>
                    <h4 className={"description"}>
                      A collection of {this.author.postCount} posts
                    </h4>
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"blog-card-container"}>
                    <div className={"blog-list"}>
                      {this.state.defaultQuery.map(({ node }) => (
                        <Link
                          className={"blog-card"}
                          key={node.id}
                          to={`/blog/${node.slug}`}
                        >
                          <div
                            className={"image"}
                            style={{
                              backgroundImage:
                                "url('" + node.feature_image + "')",
                            }}
                          />
                          <div className={"content"}>
                            <div className={"header"}>
                              <h6 className={"blog-tag"}>
                                {node.primary_tag.name}
                              </h6>
                            </div>
                            <h4 className={"title"}>{node.title}</h4>
                            <p className={"description"}>{node.excerpt}</p>
                            <div className={"card-footer"}>
                              <div className={"author"}>
                                <div className={"group"}>
                                  <img
                                    className={"avatar"}
                                    src={node.primary_author.profile_image}
                                  />
                                  <p className={"name"}>
                                    {node.primary_author.name}
                                  </p>
                                </div>
                                <p className={"read-time"}>
                                  {node.reading_time} MIN READ
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}

export default BlogAuthor
